.App {
  text-align: center;
}

.d-none{
  display:none;
}

.page-barcode{
  display: grid;
  grid-template-rows: repeat(15, 1fr);
  gap: 0;
  text-align: center;
  grid-auto-flow: column;
}

.barcode-wrap{
  position: relative;
}

.barcode_order{
  position: absolute;
  top:-3px;
  left:0;
  right:0;
  width: 50px;
  margin:auto;
  font-size: 10px;
  z-index: 1;
}
@media print {
  .page-barcode {
    page-break-after: always;
    page-break-before: always;
  }
}